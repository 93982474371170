var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.headers)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.data,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"ID","show-expand":"","dark":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Registro de entrada")]),_c('v-spacer'),(_vm.activities)?_c('v-select',{attrs:{"items":_vm.activities,"label":"Actividad","itemValue":"id","itemText":"tName","outlined":"","hide-details":"","dense":""},model:{value:(_vm.selectedActivity),callback:function ($$v) {_vm.selectedActivity=$$v},expression:"selectedActivity"}}):_vm._e(),_c('v-spacer'),_c('date-picker',{attrs:{"onChange":_vm.handleChangeDate}}),(1==2)?_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","dark":""},on:{"click":_vm.handleExportPDF}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-pdf-box")])],1):_vm._e(),_c('v-spacer'),(_vm.onSearch)?_c('search',{attrs:{"onSearch":_vm.onSearch}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleGetData}},[_vm._v(" refrescar ")])],1)]},proxy:true},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.handleCheckin(item)}}},[_vm._v(_vm._s(item.ident.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleCheckin(item)}}},[_vm._v(" registrar ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('expanded-item',{attrs:{"item":item,"locale":_vm.locale}})],1)]}},{key:"footer",fn:function(){return [_c('pagination',{attrs:{"startIndex":_vm.startIndex,"itemsPerPage":_vm.itemsPerPage,"count":_vm.count,"onGetData":_vm.handleGetData,"locale":_vm.locale,"dark":true}})]},proxy:true}],null,false,2764883263)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }